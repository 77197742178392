import React, { FC } from "react";
import UBOCountryCircles from "components/molecules/UBOCountryCircles";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import type { UboReportNode } from "api/ubo-reports";
import S from "./styles";

interface Props {
  data: UboReportNode["data"];
  isBusiness: boolean;
}

const UBOProfileCard: FC<Props> = ({
  data: { type, label, identifiers, countries, addresses },
  isBusiness
}) => {
  const renderAddresses = () => {
    if (addresses.length === 0) {
      return "None Identified";
    }

    return addresses.map(address => (
      <div key={`UBOProfileCardAddress-${address}`}>{`${address}`}</div>
    ));
  };

  const renderIdentifiers = () => {
    if (identifiers.length === 0) {
      return "None Identified";
    }

    return identifiers.map(({ label: identifierLabel, value }) => (
      <div
        key={`UBOProfileCardIdentifier-${identifierLabel}-${value}`}
      >{`${identifierLabel}: ${value}`}</div>
    ));
  };

  return (
    <S.Container>
      <S.Header>
        <S.HeaderIcon>
          {isBusiness ? <S.OrgIcon /> : <S.PersonIcon />}
        </S.HeaderIcon>

        <S.TitleContainer>
          <S.HeaderTitle>{label}</S.HeaderTitle>
          <S.HeaderSubtitle>{type}</S.HeaderSubtitle>
        </S.TitleContainer>
      </S.Header>

      <UBOCountryCircles countries={countries} />

      <S.DataContainer>
        <S.DataTitle>Identifiers</S.DataTitle>
        <S.DataItems>{renderIdentifiers()}</S.DataItems>
      </S.DataContainer>

      <S.DataContainer>
        <S.DataTitle>Address</S.DataTitle>
        <S.DataItems>{renderAddresses()}</S.DataItems>
      </S.DataContainer>

      <ButtonNew
        type={ButtonType.OutlinedLight}
        size={ButtonSize.Medium}
        text="New report"
        disabled
      />
    </S.Container>
  );
};

export default UBOProfileCard;
