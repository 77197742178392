import React, { FC, useState, useEffect } from "react";
import UpcReportsApi from "api/upc-reports";
import { useEnquiryId } from "util/hooks/useEnquiryId";

import BasicDetails, { BasicDetail } from "components/molecules/BasicDetails";

interface Props {
  title: string;
}

const UPCDetails: FC<Props> = ({ title }) => {
  const enquiryId = useEnquiryId();
  const [upcData, setUpcData] = useState<BasicDetail[][] | undefined>(
    undefined
  );

  useEffect(() => {
    const api = new UpcReportsApi();

    api
      .getDetails({ enquiryId })
      .then(({ response }) => setUpcData(response))
      .catch(() => setUpcData(undefined));
  }, [enquiryId]);

  if (!upcData) return null;

  return <BasicDetails title={title} details={upcData} />;
};

export default UPCDetails;
