export enum UboEntityType {
  person = "Person",
  company = "Company",
  root = "Root",
  edgeLabel = "EdgeLabel"
}

export enum UboNodeType {
  entity = "UboEntityNode",
  label = "UboLabelNode"
}

interface UboResponseDataNode {
  $type: UboNodeType.entity;
  id: string;
  name: string;
  entityType: UboEntityType.person | UboEntityType.company | UboEntityType.root;
  addresses: string[];
  countries: {
    iso2: string;
    name: string;
  }[];
  identifiers: {
    value: string;
    label: string;
  }[];
  sources: {
    provider: string;
    url: string;
    recordId: string;
  }[];
}

interface UboResponseEdgeLabelNode {
  $type: UboNodeType.label;
  id: string;
  name: string;
  entityType: UboEntityType.edgeLabel;
  shares: {
    asOfDate: string;
    percentage?: number;
    numShares?: number;
    monetaryValue?: {
      value: number;
      currency: string;
    };
  };
}

type UboResponseNode = Partial<UboResponseDataNode & UboResponseEdgeLabelNode>;

interface UboResponseEdge {
  id: string;
  type: string;
  fromNodeId: string;
  toNodeId: string;
}

export interface UboResponse {
  maxDepth: number;
  nodes: UboResponseNode[];
  edges: UboResponseEdge[];
}

export interface UboReportIdentifier {
  value: string;
  label: string;
}

export interface UboReportCountry {
  iso2: string;
  name: string;
}

export interface UboReportShares {
  asOfDate: string;
  percentage?: number;
  numShares?: number;
  monetaryValue?: {
    value: number;
    currency: string;
  };
}

export interface UboReportSource {
  provider: string;
  url: string;
  recordId: string;
}

export interface UboReportNode {
  id: string;
  type: UboEntityType;
  width: number;
  height: number;
  position: { x: number; y: number };
  data: {
    label: string;
    type: UboEntityType;
    shares?: UboReportShares;
    addresses: string[];
    countries: UboReportCountry[];
    identifiers: UboReportIdentifier[];
    sources: UboReportSource[];
  };
}

export interface UboReportEdge {
  id: string;
  source: string;
  target: string;
}

export interface UboReport {
  nodes: UboReportNode[];
  edges: UboReportEdge[];
}
