import React, { FC } from "react";
import { Mail } from "react-feather";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import S from "./styles";

const ErrorScreen: FC = () => {
  const onContactSupport = () => {
    window.open(`mailto:support@xapien.com`, "_blank", "noopener,noreferrer");
  };
  return (
    <S.Container>
      <S.Content>
        <S.IconContainer>
          <S.Icon />
        </S.IconContainer>

        <S.Heading level={4}> This data is not available</S.Heading>

        <S.Paragraph>
          We are unable to display the Upstream ownership preview (UBO) data for
          this report at the moment. This could be due to limited or
          inaccessible data. For further assistance, please reach out to the
          Customer Success team.
        </S.Paragraph>

        <ButtonNew
          onClick={onContactSupport}
          type={ButtonType.FilledLight}
          size={ButtonSize.Medium}
          text="Customer Success"
          IconTrailing={Mail}
        />
      </S.Content>
    </S.Container>
  );
};

export default ErrorScreen;
