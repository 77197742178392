import React, { useState, useContext, useMemo } from "react";
import { observer } from "mobx-react";

import { Resizable } from "re-resizable";

import SectionHeader from "pages/report/SectionHeader";
import { DiImage } from "components/molecules/DiImage";
import { ImageSizeToUse } from "util/ImageSizeToUse";
import InfoGraphic from "components/molecules/InfoGraphic";
import { transformWebAndMediaSourcesForInspector } from "components/organisms/WithInspector/util";
import { orgReportSectionTitles } from "util/reportSectionTitles";
import { grey, red } from "styles/colors";
import LocationRiskTag from "components/atoms/LocationRiskTag";
import { InfographicStateContext } from "util/context/InfographicStateContext";
import BasicDetails, {
  BasicDetailsType
} from "components/molecules/BasicDetails";
import { REPORT_TYPES } from "util/reportTypes";
import { useReports } from "util/hooks/useReports";
import { isPDX } from "static-config";

import ThemedScreeningInfographics from "ThemedScreeningInfographics";

import S, { classNameOverrides } from "./styles";

const LegalEntityOverview = React.forwardRef(
  (
    {
      imageOfLogo,
      orgScreeningSummary,
      stateOwnedCompanies,
      sectionRefs,
      displaySubjectImage = true,
      infographicLayoutTemplate,
      title,
      // Key details props
      legalName,
      otherNames,
      companyStatus,
      incorporationDate,
      stateOwned,
      registeredAddresses,
      companyTypes,
      jurisdiction,
      publiclyListed,
      registrationNumber,
      otherIdentifiers
    },
    ref
  ) => {
    const reportStore = useReports();

    const { riskData } = reportStore;

    const webAndMediaSources = useMemo(() => {
      const sources = {};
      reportStore.webAndMediaData.forEach(source => {
        sources[source.sourceId] = source;
      });
      return sources;
    }, [reportStore.webAndMediaData]);

    const [hasLogoErrored, setLogoErrored] = useState(false);

    const finCrimeData = useMemo(
      () =>
        transformWebAndMediaSourcesForInspector(
          riskData?.finCrime?.directRiskCategories,
          webAndMediaSources
        ),
      [riskData?.finCrime?.directRiskCategories, webAndMediaSources]
    );

    const esgData = useMemo(
      () =>
        transformWebAndMediaSourcesForInspector(
          riskData?.eSG?.directRiskCategories,
          webAndMediaSources
        ),
      [riskData?.eSG?.directRiskCategories, webAndMediaSources]
    );

    const proceedingsData = useMemo(
      () =>
        transformWebAndMediaSourcesForInspector(
          riskData?.proceedings?.directRiskCategories,
          webAndMediaSources
        ),
      [riskData?.proceedings?.directRiskCategories, webAndMediaSources]
    );

    const otherFlagsData = useMemo(
      () =>
        transformWebAndMediaSourcesForInspector(
          riskData?.otherFlags?.directRiskCategories,
          webAndMediaSources
        ),
      [riskData?.otherFlags?.directRiskCategories, webAndMediaSources]
    );

    const { setDisplayAdditionalLabel, isAnyInfographicInspectorOpen } =
      useContext(InfographicStateContext);

    const setterPropsForDisplayingAdditionalLabel = {
      onMouseOver: () => {
        setDisplayAdditionalLabel(true);
      },
      onMouseOut: () => {
        if (!isAnyInfographicInspectorOpen) setDisplayAdditionalLabel(false);
      }
    };

    const renderStateOwnershipSummaryInfo = () => {
      if (!stateOwnedCompanies?.stateOwnerships?.length) {
        return null;
      }

      return (
        <Resizable
          handleStyles={{ bottom: { bottom: -25 } }}
          bounds="window"
          minHeight={275}
          defaultSize={{ height: 240 }}
          enable={{
            top: false,
            right: false,
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
        >
          <S.StateOwnedCompanies>
            {stateOwnedCompanies?.stateOwnerships?.map(country => {
              return (
                <S.InfoSummaryRow
                  key={country.countryName}
                  icon={
                    <S.CountryFlag
                      code={country.countryCode}
                      showCodeString={false}
                    />
                  }
                  heading={
                    <S.RelatedCountryHeader>
                      {country.countryName}{" "}
                    </S.RelatedCountryHeader>
                  }
                  content={
                    <div>
                      {country.riskFlags?.length ? (
                        <S.RiskAssigners>
                          {country.riskFlags?.map(risk => (
                            <LocationRiskTag
                              key={risk}
                              riskAssigner={risk.assigner}
                              className={classNameOverrides.locationRiskTag}
                            />
                          ))}
                        </S.RiskAssigners>
                      ) : null}
                      {country.companiesWithSources?.length > 0 && (
                        <S.List>
                          {country.companiesWithSources?.map((comp, index) => {
                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <S.ListItem key={`${comp.name}_${index}`}>
                                {comp.companyName}
                              </S.ListItem>
                            );
                          })}
                        </S.List>
                      )}
                    </div>
                  }
                />
              );
            })}
          </S.StateOwnedCompanies>
        </Resizable>
      );
    };

    const renderScreeningRiskIcons = () => {
      const confirmedStateOwnerships =
        stateOwnedCompanies?.confidence === "Confirmed"
          ? stateOwnedCompanies?.stateOwnerships?.length
          : 0;

      const unconfirmedStateOwnerships =
        stateOwnedCompanies?.confidence === "Unconfirmed"
          ? stateOwnedCompanies?.stateOwnerships?.length
          : 0;

      let stateOwnershipsInfographicColour = grey.mid;

      if (isPDX) {
        if (confirmedStateOwnerships) {
          stateOwnershipsInfographicColour = red.directRiskOutline;
        }
      } else {
        stateOwnershipsInfographicColour = grey.ghost;
      }

      const StateOwnershipsInfographic = (
        <InfoGraphic
          RiskIcon={S.StateOwnedFlagIcon}
          riskCount={isPDX ? confirmedStateOwnerships : undefined}
          title="State owned"
          inspectorSubtext={
            <div>
              Number of related organisations where a government or state holds
              significant control.
            </div>
          }
          inspectorHeader={`${
            stateOwnedCompanies?.stateOwnerships?.length
          } ${stateOwnedCompanies?.confidence?.toLowerCase()} state owned ${
            stateOwnedCompanies?.stateOwnerships?.length === 1
              ? "organisation"
              : "organisations"
          }`}
          inspectorContent={renderStateOwnershipSummaryInfo()}
          tooltipExplainerText={
            isPDX ? (
              <div>
                We have identified {confirmedStateOwnerships} state owned
                related{" "}
                {confirmedStateOwnerships !== 1
                  ? "organisations"
                  : "organisation"}
                .
                {unconfirmedStateOwnerships ? (
                  <div>
                    Xapien also identified {unconfirmedStateOwnerships}{" "}
                    {unconfirmedStateOwnerships !== 1 ? "people" : "person"}{" "}
                    with{" "}
                    {unconfirmedStateOwnerships !== 1
                      ? "names similar"
                      : "a similar name"}{" "}
                    to your subject, but there was insufficient information in
                    the source to confirm or discard them as your subject.
                  </div>
                ) : null}
              </div>
            ) : (
              <div>Coming soon</div>
            )
          }
          colourOverride={stateOwnershipsInfographicColour}
          additionalLabel={`+${unconfirmedStateOwnerships} for review`}
          sectionToJumpToRef={
            sectionRefs && sectionRefs[orgReportSectionTitles.SCREENING]
          }
          linkToSectionText="Screening section"
          disabled={!isPDX}
        />
      );

      return (
        <ThemedScreeningInfographics
          screeningData={orgScreeningSummary}
          reportSectionRefs={sectionRefs}
          reportType={REPORT_TYPES.organisation}
          CustomInfographic={isPDX ? StateOwnershipsInfographic : undefined}
        />
      );
    };

    const renderRiskIcons = () => {
      const indirectFinCrimeCount =
        riskData?.finCrime?.indirectRiskCategories?.length ?? 0;
      const indirectESGCount =
        riskData?.eSG?.indirectRiskCategories?.length ?? 0;
      const indirectProceedingsCount =
        riskData?.proceedings?.indirectRiskCategories?.length ?? 0;
      const indirectOtherFlagsCount =
        riskData?.otherFlags?.indirectRiskCategories?.length ?? 0;

      const directFinCrimeCount = finCrimeData?.filter(
        data => data.sources?.length
      )?.length;
      const directEsgCount = esgData?.filter(
        data => data.sources?.length
      )?.length;
      const directProceedingsCount = proceedingsData?.filter(
        data => data.sources?.length
      )?.length;
      const directOtherFlagsCount = otherFlagsData?.filter(
        data => data.sources?.length
      )?.length;

      const directFinCrimeCountExplainer = (
        <span>
          <strong>{directFinCrimeCount} directly</strong> linked{" "}
          <strong>{directFinCrimeCount !== 1 ? "risks" : "risk"}</strong>{" "}
          relating to potential <strong>financial crimes</strong>.
        </span>
      );

      const directEsgCountExplainer = (
        <span>
          <strong>{directEsgCount} directly</strong> linked{" "}
          <strong>{directEsgCount !== 1 ? "risks" : "risk"}</strong> relating to{" "}
          <strong>ESG (Environmental, Social and Governance)</strong>.
        </span>
      );

      const directProceedingsCountExplainer = (
        <span>
          <strong>{directProceedingsCount} directly</strong> linked{" "}
          <strong>{directProceedingsCount !== 1 ? "risks" : "risk"}</strong>{" "}
          relating to <strong>accusations, legal proceedings</strong>, and{" "}
          <strong>convictions</strong>.
        </span>
      );

      const directOtherFlagsCountExplainer = (
        <span>
          <strong>{directOtherFlagsCount} directly</strong> linked{" "}
          <strong>{directOtherFlagsCount !== 1 ? "risks" : "risk"}</strong>{" "}
          relating to <strong>controversy</strong> or{" "}
          <strong>potential criminal activity</strong>.
        </span>
      );

      return (
        <>
          <InfoGraphic
            RiskIcon={S.FinCrimeRiskFlagIcon}
            riskCount={directFinCrimeCount}
            title="Financial crime"
            inspectorSubtext={directFinCrimeCountExplainer}
            tooltipExplainerText={
              <div>
                {directFinCrimeCountExplainer}
                <S.IndirectExplainer>
                  <strong>{indirectFinCrimeCount} indirect</strong>{" "}
                  <strong>
                    {indirectFinCrimeCount !== 1 ? "risks" : "risk"}
                  </strong>{" "}
                  that {indirectFinCrimeCount !== 1 ? "are" : "is"} not directly
                  connected to your subject.
                </S.IndirectExplainer>
              </div>
            }
            explainerText={
              <div>
                <strong>{indirectFinCrimeCount} indirect</strong>{" "}
                <strong>
                  {indirectFinCrimeCount !== 1 ? "risks" : "risk"}
                </strong>{" "}
                that {indirectFinCrimeCount !== 1 ? "are" : "is"} not directly
                connected to your subject.
              </div>
            }
            inspectorData={finCrimeData}
            inspectorHeader={`${directFinCrimeCount} financial crime ${
              directFinCrimeCount === 1 ? "category" : "categories"
            }`}
            sectionToJumpToRef={
              sectionRefs && sectionRefs[orgReportSectionTitles.WEB_AND_MEDIA]
            }
            additionalLabel={`+${indirectFinCrimeCount ?? 0} indirect`}
          />
          <InfoGraphic
            RiskIcon={S.ESGIcon}
            riskCount={directEsgCount}
            title="ESG flags"
            inspectorSubtext={directEsgCountExplainer}
            tooltipExplainerText={
              <div>
                {directEsgCountExplainer}
                <S.IndirectExplainer>
                  <strong>{indirectESGCount} indirect</strong>{" "}
                  <strong>{indirectESGCount !== 1 ? "risks" : "risk"}</strong>{" "}
                  that {indirectESGCount !== 1 ? "are" : "is"} not directly
                  connected to your subject.
                </S.IndirectExplainer>
              </div>
            }
            explainerText={
              <div>
                <strong>{indirectESGCount} indirect</strong>{" "}
                <strong>{indirectESGCount !== 1 ? "risks" : "risk"}</strong>{" "}
                that {indirectESGCount !== 1 ? "are" : "is"} not directly
                connected to your subject.
              </div>
            }
            inspectorData={esgData}
            inspectorHeader={`${directEsgCount} ESG ${
              directEsgCount === 1 ? "category" : "categories"
            }`}
            sectionToJumpToRef={
              sectionRefs && sectionRefs[orgReportSectionTitles.WEB_AND_MEDIA]
            }
            additionalLabel={`+${indirectESGCount ?? 0} indirect`}
          />
          <InfoGraphic
            RiskIcon={S.MajorCrimesFlagIcon}
            riskCount={directProceedingsCount}
            title="Proceedings"
            inspectorData={proceedingsData}
            inspectorSubtext={directProceedingsCountExplainer}
            tooltipExplainerText={
              <div>
                {directProceedingsCountExplainer}
                <S.IndirectExplainer>
                  <strong>{indirectProceedingsCount} indirect</strong>{" "}
                  <strong>
                    {indirectProceedingsCount !== 1 ? "risks" : "risk"}
                  </strong>{" "}
                  that {indirectProceedingsCount !== 1 ? "are" : "is"} not
                  directly connected to your subject.
                </S.IndirectExplainer>
              </div>
            }
            explainerText={
              <div>
                <strong>{indirectProceedingsCount} indirect</strong>{" "}
                <strong>
                  {indirectProceedingsCount !== 1 ? "risks" : "risk"}
                </strong>{" "}
                that {indirectProceedingsCount !== 1 ? "are" : "is"} not
                directly connected to your subject.
              </div>
            }
            inspectorHeader={`${directProceedingsCount} proceedings ${
              directProceedingsCount === 1 ? "category" : "categories"
            }`}
            sectionToJumpToRef={
              sectionRefs && sectionRefs[orgReportSectionTitles.WEB_AND_MEDIA]
            }
            additionalLabel={`+${indirectProceedingsCount ?? 0} indirect`}
          />
          <InfoGraphic
            RiskIcon={S.RiskFlagIcon}
            riskCount={directOtherFlagsCount}
            title="Other flags"
            inspectorSubtext={directOtherFlagsCountExplainer}
            tooltipExplainerText={
              <div>
                {directOtherFlagsCountExplainer}
                <S.IndirectExplainer>
                  <strong>{indirectOtherFlagsCount} indirect</strong>{" "}
                  <strong>
                    {indirectOtherFlagsCount !== 1 ? "risks" : "risk"}
                  </strong>{" "}
                  that {indirectOtherFlagsCount !== 1 ? "are" : "is"} not
                  directly connected to your subject.
                </S.IndirectExplainer>
              </div>
            }
            explainerText={
              <div>
                <strong>{indirectOtherFlagsCount} indirect</strong>{" "}
                <strong>
                  {indirectOtherFlagsCount !== 1 ? "risks" : "risk"}
                </strong>{" "}
                that {indirectOtherFlagsCount !== 1 ? "are" : "is"} not directly
                connected to your subject.
              </div>
            }
            inspectorData={otherFlagsData}
            inspectorHeader={`${directOtherFlagsCount} other flag ${
              directOtherFlagsCount === 1 ? "category" : "categories"
            }`}
            sectionToJumpToRef={
              sectionRefs && sectionRefs[orgReportSectionTitles.WEB_AND_MEDIA]
            }
            additionalLabel={`+${indirectOtherFlagsCount ?? 0} indirect`}
          />
        </>
      );
    };

    const renderOrgLogo = () => {
      return (
        <S.OrgLogoContainer>
          {imageOfLogo && !hasLogoErrored ? (
            <DiImage
              alt="Organisation logo"
              image={imageOfLogo}
              imageSizeToUse={ImageSizeToUse.Small}
              className={classNameOverrides.orgLogoImage}
              onError={() => setLogoErrored(true)}
              lazyLoad={false}
              width="88px"
              height="94px"
              isCopyrighted
            />
          ) : (
            <S.BuildingIconContainer>
              <S.BuildingIcon />
            </S.BuildingIconContainer>
          )}
        </S.OrgLogoContainer>
      );
    };

    const keyDetails = [
      [
        {
          title: "Legal name",
          itemType: BasicDetailsType.text,
          items: legalName
            ? [{ value: legalName.text, sources: legalName.sources }]
            : []
        },
        {
          title: "Other names",
          itemType: BasicDetailsType.text,
          items:
            otherNames?.map(({ text, sources }) => ({
              value: text,
              sources
            })) ?? []
        },
        {
          title: "Company types",
          itemType: BasicDetailsType.text,
          items:
            companyTypes?.map(({ text, sources }) => ({
              value: text,
              sources
            })) ?? []
        },
        {
          title: "Company status",
          itemType: BasicDetailsType.text,
          items: companyStatus
            ? [{ value: companyStatus.label, sources: companyStatus.sources }]
            : []
        }
      ],
      [
        {
          title: "Date of incorporation",
          itemType: BasicDetailsType.text,
          items: incorporationDate
            ? [
                {
                  value: `${incorporationDate.day} ${incorporationDate.monthShort} ${incorporationDate.year}`,
                  sources: incorporationDate.sources
                }
              ]
            : []
        },
        {
          title: "Jurisdiction",
          itemType: BasicDetailsType.country,
          items: jurisdiction
            ? [
                {
                  code: jurisdiction.countryCode,
                  name: jurisdiction.countryName,
                  sources: jurisdiction.sources
                }
              ]
            : []
        },
        {
          title: "Publicly listed",
          itemType: BasicDetailsType.text,
          items: publiclyListed
            ? [
                {
                  value: publiclyListed.text,
                  sources: publiclyListed.sources
                }
              ]
            : []
        },
        {
          title: "State owned",
          itemType: BasicDetailsType.text,
          items: stateOwned
            ? [
                {
                  value: stateOwned.value ? "Yes" : "No",
                  sources: stateOwned.sources
                }
              ]
            : []
        }
      ],
      [
        {
          title: "Registration number",
          itemType: BasicDetailsType.text,
          items:
            registrationNumber?.map(({ value, sources }) => ({
              value,
              sources
            })) ?? []
        },
        {
          title: "Legal Entity Identifier (LEI)",
          itemType: BasicDetailsType.text,
          items: otherIdentifiers
            ? otherIdentifiers.map(({ value, sources }) => ({
                value,
                sources
              }))
            : []
        },
        {
          title: "Other Identifiers",
          itemType: BasicDetailsType.text,
          items: otherIdentifiers
            ? otherIdentifiers.map(({ value, sources }) => ({
                value,
                sources
              }))
            : []
        }
      ],
      [
        {
          title: "Registered address",
          itemType: BasicDetailsType.address,
          items: registeredAddresses
            ? registeredAddresses.map(({ address, sources }) => ({
                values: address,
                sources
              }))
            : []
        }
      ]
    ];

    return (
      <S.LegalEntityOverviewSection ref={ref} className="report-section">
        <SectionHeader title={title} isContractable={false} />
        <S.OverviewContainer
          infographicLayoutTemplate={infographicLayoutTemplate}
        >
          {displaySubjectImage && (
            <>
              <S.OrgLogoHeader>
                <S.OrgLogoSectionTitle>Logo</S.OrgLogoSectionTitle>
              </S.OrgLogoHeader>
              <S.OrgLogo>{renderOrgLogo()}</S.OrgLogo>
            </>
          )}
          <S.ScreeningRiskHeader {...setterPropsForDisplayingAdditionalLabel}>
            <S.RiskIconsSectionTitle>Screening summary</S.RiskIconsSectionTitle>
          </S.ScreeningRiskHeader>
          <S.ScreeningRiskIcons {...setterPropsForDisplayingAdditionalLabel}>
            {renderScreeningRiskIcons()}
          </S.ScreeningRiskIcons>
          <S.RiskHeader {...setterPropsForDisplayingAdditionalLabel}>
            <S.RiskIconsSectionTitle>Media for review</S.RiskIconsSectionTitle>
          </S.RiskHeader>
          <S.RiskIcons {...setterPropsForDisplayingAdditionalLabel}>
            {renderRiskIcons()}
          </S.RiskIcons>
        </S.OverviewContainer>
        <BasicDetails title="Key details" details={keyDetails} />
      </S.LegalEntityOverviewSection>
    );
  }
);

export default observer(LegalEntityOverview);
