import React, { useState, useEffect, useContext, useCallback } from "react";
import { apm } from "@elastic/apm-rum";
import { useNavigate, useLocation } from "react-router-dom";
import qs from "qs";
import Select, {
  CSSObjectWithLabel,
  OnChangeValue,
  OptionProps,
  components
} from "react-select";
import { AnimatePresence } from "framer-motion";

import {
  ENTITY_TYPE,
  DEFAULT_CONTEXT_PER_SUBJECT_TYPE,
  SUBJECT_CONTEXTS
} from "pages/define/utils";
import DefineStage from "pages/define/DefineStage";
import VerifyStage from "pages/define/VerifyStage";
import SubjectSelectionStage from "pages/define/SubjectSelectionStage";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import { currentVersion } from "services/version";
import { ButtonKind } from "components/atoms/Button/types";
import Button from "components/atoms/Button";
import { NOTIFICATION_TYPES } from "components/atoms/Notification";
import AutoShareToggle from "components/organisms/AutoshareToggle";
import {
  Context,
  ContextType,
  EnquiryRequest,
  EnquiryResponse,
  EntityType
} from "api/enquiry/types";
import { Features } from "api/feature/types";
import { OrgSuggestion, ValidationResponse } from "api/define/types";

// @ts-ignore
import theme from "theme";

import { grey } from "styles/colors";

import S, { classNameOverrides } from "./styles";

const CONTEXT_LIMIT = 3;
const stages = {
  DEFINE: 0,
  SUBJECT_SELECT: 1,
  VERIFY: 2,
  TRY_AGAIN: 3
};

const getInitialContexts = (
  subjectType: EntityType,
  isAdvancedSearchModeActive: boolean
): Record<string, Context> => {
  // A special case: only organisation searches support non-advanced mode.
  // If we're in non-advanced mode then we need to force the context type to
  // be freeText, otherwise use the context type as defined in the utilities.
  if (subjectType === ENTITY_TYPE.Organisation && !isAdvancedSearchModeActive) {
    return {
      0: {
        type: SUBJECT_CONTEXTS?.organisation?.freeText?.type,
        value: null,
        id: SUBJECT_CONTEXTS?.organisation?.freeText?.id
      }
    };
  }
  const defaultContextOption =
    SUBJECT_CONTEXTS[subjectType][
      DEFAULT_CONTEXT_PER_SUBJECT_TYPE[subjectType]
    ];

  return {
    0: {
      type: defaultContextOption.type,
      value: null,
      id: defaultContextOption.id
    }
  };
};

const Option = (props: OptionProps<FeatureOption>) => {
  const { isSelected, label } = props;
  return (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={isSelected} onChange={() => null} />{" "}
        <span>{label}</span>
      </components.Option>
    </div>
  );
};

type FeatureOption = { label: string; value: string };

interface Props {
  createEnquiry: (request: EnquiryRequest) => EnquiryResponse;
  validateInput: (
    subject: { type: EntityType; item: string },
    contexts: { type: string; item: string }[],
    experimentalModeActive: boolean,
    features: Features
  ) => ValidationResponse;
  nameOnlySearch: boolean;
  fetchFeatures: () => Features;
  getSystemStatus: () => string;
}

const Define = ({
  createEnquiry,
  validateInput,
  nameOnlySearch,
  fetchFeatures,
  getSystemStatus
}: Props) => {
  const location = useLocation();

  const [subjectType, setSubjectType] = useState(() => {
    const { entity } = qs.parse(location.search, {
      ignoreQueryPrefix: true
    });

    return entity === ENTITY_TYPE.Person || entity === ENTITY_TYPE.Organisation
      ? entity
      : theme.defaultSubjectType;
  });
  const [subjectName, setSubjectName] = useState("");
  const [subjectNameOverride, setSubjectNameOverride] = useState<
    string | undefined
  >();
  const [contextOverride, setContextOverride] = useState<
    Record<string, Context> | string | undefined
  >();
  const [contextTypeOverride, setContextTypeOverride] = useState<
    ContextType | undefined
  >();
  const [isAdvancedSearchModeActive, setIsAdvancedSearchModeActive] = useState(
    subjectType === ENTITY_TYPE.Person
  );
  const [contexts, setContexts] = useState(
    getInitialContexts(subjectType, isAdvancedSearchModeActive)
  );
  const [features, setFeatures] = useState<Features>({});
  const [stage, setStage] = useState(stages.DEFINE);
  const [verificationStageErrored, setVerificationStageErrored] =
    useState(false);
  const [orgSuggestions, setOrgSuggestions] = useState<
    OrgSuggestion[] | undefined
  >();

  const [projectReference, setProjectReference] = useState<
    string | undefined
  >();

  const [isVerifying, setIsVerifying] = useState(false);

  const [enquiryId, setEnquiryId] = useState<string | undefined>();

  const navigate = useNavigate();

  const diagnosticsMode = useContext(DiagnosticsModeContext).enabled;

  const versionNumber = `Version ${currentVersion.display}`;
  const showFeatureSwitches = diagnosticsMode;
  const options = Object.keys(showFeatureSwitches ? features : {})
    .sort()
    .map(name => {
      return { label: name, value: name };
    });
  const selectedFeatures = options.filter(o => features[o.value]);

  const [systemStatus, setSystemStatus] = useState("");

  const [showSystemStatusNotification, setShowSystemStatusNotification] =
    useState(false);

  const parseUrlAndExtractEnquiryData = useCallback(
    (queryParameters: qs.ParsedQs) => {
      // if the autorun query is present in the URL and is set to true, then skip to the VerifyStage.
      if (queryParameters.autorun === "true") {
        setStage(stages.VERIFY);
      }

      const parsedSubjectType =
        queryParameters.subjecttype ?? ENTITY_TYPE.Person;
      const parsedSubjectName = queryParameters.subject;
      let queryStringContexts;

      try {
        if (
          queryParameters.context &&
          typeof queryParameters.context === "string"
        ) {
          queryStringContexts = JSON.parse(queryParameters.context);
        } else {
          queryStringContexts = [];
        }
      } catch (err) {
        queryStringContexts = [];
      }

      const contextsToDisplay = queryStringContexts
        .slice(0, CONTEXT_LIMIT)
        .reduce(
          (acc: Record<string, Context>, context: Context, index: number) => {
            acc[index] = {
              type: context.type,
              value: context.value?.trim()
            };
            return acc;
          },
          getInitialContexts(subjectType, isAdvancedSearchModeActive)
        );

      return [parsedSubjectName, parsedSubjectType, contextsToDisplay];
    },
    [isAdvancedSearchModeActive, subjectType]
  );

  const onSubjectTypeChanged = (newSubjectType: EntityType) => {
    setSubjectType(newSubjectType);
    setSubjectName("");
    let willAdvancedSearchModeBeActive;

    if (newSubjectType === ENTITY_TYPE.Organisation) {
      willAdvancedSearchModeBeActive = false;
      setIsAdvancedSearchModeActive(willAdvancedSearchModeBeActive);
      setContexts(
        getInitialContexts(newSubjectType, willAdvancedSearchModeBeActive)
      );
    } else {
      willAdvancedSearchModeBeActive = true;
      setIsAdvancedSearchModeActive(willAdvancedSearchModeBeActive);
      setContexts(
        getInitialContexts(newSubjectType, willAdvancedSearchModeBeActive)
      );
    }
  };

  useEffect(() => {
    if (enquiryId) {
      navigate(`../report/preparing/${enquiryId}`);
    }
  }, [enquiryId, navigate]);

  useEffect(() => {
    if (systemStatus?.length > 0) {
      setShowSystemStatusNotification(true);
    }
  }, [systemStatus]);

  useEffect(() => {
    const fetchStatus = async () => {
      const message = await getSystemStatus();
      setSystemStatus(message);
    };

    fetchStatus();
  }, [getSystemStatus]);

  useEffect(() => {
    (async () => {
      const fetchedFeatures: Features = await fetchFeatures();
      if (nameOnlySearch) {
        fetchedFeatures.SubjectNameWebSearches = true;
      }
      setFeatures(fetchedFeatures);
    })();

    const { _entity, ...queryParameters } = qs.parse(location.search, {
      ignoreQueryPrefix: true
    });

    // Only run this if query paramaters need to be extracted
    if (Object.keys(queryParameters)?.length) {
      const [parsedSubjectName, parsedSubjectType, parsedContexts] =
        parseUrlAndExtractEnquiryData(queryParameters);

      onSubjectTypeChanged(parsedSubjectType);
      setSubjectName(parsedSubjectName);
      setContexts(parsedContexts);

      // Ensure the advanced search view for organisation searches is visible for when the subject type is an organisation
      if (parsedSubjectType === ENTITY_TYPE.Organisation) {
        setIsAdvancedSearchModeActive(true);
      }
    }
  }, [
    fetchFeatures,
    location.search,
    nameOnlySearch,
    parseUrlAndExtractEnquiryData
  ]);

  const handleFeatureChange = (
    newValue: OnChangeValue<FeatureOption, true>
  ) => {
    const newFeatures = Object.fromEntries(
      Object.entries(features).map(kv => [
        kv[0],
        newValue.findIndex(o => o.value === kv[0]) >= 0
      ])
    );
    setFeatures(newFeatures);
  };

  const onToggleAdvancedSearchMode = () => {
    if (isAdvancedSearchModeActive) {
      setContexts({
        0: {
          type: SUBJECT_CONTEXTS?.organisation?.freeText?.type,
          value: null
        }
      });
    } else {
      // Given we've togged the advanced search mode then the value
      // of isAdvancedSearchModeActive is the current value. We not (!) it
      // here to get what will be the new value.
      setContexts(getInitialContexts(subjectType, !isAdvancedSearchModeActive));
    }

    setIsAdvancedSearchModeActive(prevState => !prevState);
  };

  const onOrgSuggestionsReceived = () => {
    setStage(stages.SUBJECT_SELECT);
  };

  const onSearchConfirmed = () => {
    setStage(stages.VERIFY);
  };

  const createEnquiryInternal = async () => {
    setEnquiryId(undefined);
    setShowSystemStatusNotification(false);
    try {
      // If the user has opted to use the proposed subject name and/or
      // context values then use those in the enquiry. Otherwise use
      // the user's original inputs.
      const subjectNameToUse = subjectNameOverride ?? subjectName;
      // Default to original context inputs.
      let contextToUse = Object.keys(contexts).reduce(
        (acc: Context[], contextKey) => {
          const contextObj = contexts[contextKey];
          if (!contextObj.value) {
            return acc;
          }
          return [...acc, { type: contextObj.type, value: contextObj.value }];
        },
        []
      );

      // Not Advance search...
      if (contextOverride) {
        if (typeof contextOverride === "object" || !contextTypeOverride) {
          contextToUse = Object.keys(contextOverride).reduce(
            (acc: Context[], contextKey) => {
              const contextObj = (contextOverride as Record<string, Context>)[
                contextKey
              ];
              if (!contextObj.value) {
                // Use original if no proposed suggested for that context field
                return [
                  ...acc,
                  {
                    type: contexts[contextKey].type,
                    value: contexts[contextKey].value
                  }
                ];
              }
              return [
                ...acc,
                { type: contextObj.type, value: contextObj.value }
              ];
            },
            []
          );
        } else if (typeof contextOverride === "string") {
          contextToUse = [
            {
              type: contextTypeOverride ?? Object.values(contexts)[0]?.type,
              value: contextOverride
            }
          ];
        }
      }

      const enquiryRequestConfig = {
        subject: {
          value: subjectNameToUse,
          type: subjectType
        },
        contexts: contextToUse,
        activeFeatures: features,
        projectReference
      };

      const enquiry = await createEnquiry(enquiryRequestConfig);
      setEnquiryId(enquiry.enquiryId);
    } catch (err) {
      apm.captureError(err as Error);
      console.error(err);
      setStage(stages.DEFINE);
      setVerificationStageErrored(true);
      setContextOverride(undefined);
      setContextTypeOverride(undefined);
      setSubjectNameOverride(undefined);
    }
  };

  const renderDefineStage = () => {
    return (
      <DefineStage
        subjectType={subjectType}
        onSubjectTypeChanged={onSubjectTypeChanged}
        subjectName={subjectName}
        setSubjectName={setSubjectName}
        setSubjectNameOverride={setSubjectNameOverride}
        setContextOverride={setContextOverride}
        setOrgSuggestions={setOrgSuggestions}
        contexts={contexts}
        setContexts={setContexts}
        validateInput={validateInput}
        onOrgSuggestionsReceived={onOrgSuggestionsReceived}
        onSearchConfirmed={onSearchConfirmed}
        isMounted={stage === stages.DEFINE || stage === stages.TRY_AGAIN}
        verificationStageErrored={verificationStageErrored}
        contextLimit={CONTEXT_LIMIT}
        isAdvancedSearchModeActive={isAdvancedSearchModeActive}
        setVerificationStageErrored={setVerificationStageErrored}
        projectReference={projectReference}
        setProjectReference={setProjectReference}
        // isProjectReferenceEnabled={isProjectReferenceEnabled}
        stageTitle={
          stage === stages.TRY_AGAIN
            ? "Let's be more specific"
            : "Who are you interested in?"
        }
        isVerifying={isVerifying}
        setIsVerifying={setIsVerifying}
        features={features}
      />
    );
  };

  const renderSubjectSelectionStage = () => {
    // Non-advanced search only has one context field.
    // This will always be the first item.
    // If we're rendering this stage then we've passed
    // validation and so must have this context.
    const enteredContext = contexts[0];

    return (
      <SubjectSelectionStage
        isMounted={stage === stages.SUBJECT_SELECT}
        orgSuggestions={orgSuggestions}
        setSubjectNameOverride={setSubjectNameOverride}
        setContextOverride={setContextOverride}
        setContextTypeOverride={setContextTypeOverride}
        onSuggestionSelected={() => {
          setStage(stages.VERIFY);
        }}
        subjectName={subjectName}
        contextItem={enteredContext?.value}
        onStageCancelled={() => {
          setStage(stages.TRY_AGAIN);
          setContextTypeOverride(undefined);
          setContextOverride(undefined);
          setSubjectNameOverride(undefined);
          setIsAdvancedSearchModeActive(true);
          setContexts(getInitialContexts(subjectType, true));
        }}
      />
    );
  };

  const renderVerifyStage = () => {
    return (
      <VerifyStage
        isMounted={stage === stages.VERIFY}
        createEnquiryInternal={createEnquiryInternal}
      />
    );
  };

  const renderAdvancedSearchToggle = () => {
    let footerString = "Looking for more control? Try";
    let buttonString = "Advanced Search";

    if (isAdvancedSearchModeActive) {
      footerString = "";
      buttonString = "Leave Advanced Search";
    }

    return (
      <S.AdvancedSearchOption>
        <p>{footerString}</p>{" "}
        <Button
          kind={ButtonKind.tertiary}
          onClick={onToggleAdvancedSearchMode}
          disabled={isVerifying}
        >
          {buttonString}
        </Button>
      </S.AdvancedSearchOption>
    );
  };

  const renderBackButton = () => {
    return (
      <Button
        kind={ButtonKind.tertiary}
        onClick={() => {
          setStage(stages.DEFINE);
          setContextTypeOverride(undefined);
          setContextOverride(undefined);
          setSubjectNameOverride(undefined);
        }}
      >
        Back
      </Button>
    );
  };

  const renderStartOverButton = () => {
    return (
      <Button
        kind={ButtonKind.tertiary}
        onClick={() => {
          setStage(stages.DEFINE);
          setContextTypeOverride(undefined);
          setContextOverride(undefined);
          setSubjectNameOverride(undefined);
          setIsAdvancedSearchModeActive(false);
          setContexts(getInitialContexts(subjectType, false));
          setSubjectName("");
        }}
      >
        Start over
      </Button>
    );
  };

  const customFeatureMenuStyles = {
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      width: 600,
      color: grey.dark
    }),
    container: (provided: CSSObjectWithLabel) => ({
      ...provided,
      display: "flex",
      justifyContent: "center"
    })
  };

  return (
    <S.ViewContainer>
      <S.ViewInnerContainer>
        <S.PaddingElement />
        <S.StageContainer>
          {renderDefineStage()}
          {renderSubjectSelectionStage()}
          {renderVerifyStage()}
          <AnimatePresence>
            {showSystemStatusNotification && (
              <S.NotificationContainer
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1
                }}
                exit={{ opacity: 0, transition: { duration: 0.2 } }}
                transition={{
                  duration: 0.4
                }}
              >
                <S.StatusNotification
                  notificationString={
                    <>
                      {systemStatus}
                      <div>
                        <S.NotificationOkButton
                          backgroundColor={theme.alternativePrimaryColor}
                          boxShadowColor={
                            theme.button.alternativeBoxShadowColor
                          }
                          color={theme.button.primary.color}
                          onClick={() => setShowSystemStatusNotification(false)}
                        >
                          OK
                        </S.NotificationOkButton>
                      </div>
                    </>
                  }
                  notificationType={NOTIFICATION_TYPES.prompt}
                  isCloseable
                  onCloseNotificationClick={() => {
                    setShowSystemStatusNotification(false);
                  }}
                  notificationDescriptionClass={
                    classNameOverrides.notificationDescriptionClass
                  }
                />
              </S.NotificationContainer>
            )}
          </AnimatePresence>
        </S.StageContainer>
        {showFeatureSwitches && options.length > 0 ? (
          <Select
            options={options}
            onChange={handleFeatureChange}
            value={selectedFeatures}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Option
            }}
            controlShouldRenderValue={false}
            isClearable={false}
            styles={customFeatureMenuStyles}
          />
        ) : null}
        {diagnosticsMode && <S.VersionNumber>{versionNumber}</S.VersionNumber>}
        {subjectType === ENTITY_TYPE.Organisation && stage === stages.DEFINE ? (
          renderAdvancedSearchToggle()
        ) : (
          <S.AdvancedSearchTogglePaddingElement />
        )}
        {stage === stages.DEFINE && <AutoShareToggle />}
        {subjectType === ENTITY_TYPE.Organisation &&
        stage === stages.SUBJECT_SELECT
          ? renderBackButton()
          : null}
        {subjectType === ENTITY_TYPE.Organisation && stage === stages.TRY_AGAIN
          ? renderStartOverButton()
          : null}
      </S.ViewInnerContainer>
      {theme.footer?.logo ? (
        <S.PoweredBy>{theme.footer?.logo}</S.PoweredBy>
      ) : null}
    </S.ViewContainer>
  );
};

export default Define;
