import React, { FC } from "react";
import { Position } from "reactflow";
import { UBOHandle } from "components/atoms/UBO";
import type { UboReportNode } from "api/ubo-reports";
import S from "./styles";

interface Props {
  data: UboReportNode["data"];
}

const EdgeLabelNode: FC<Props> = ({ data: { label, shares } }) => {
  const sharePercentageText = shares?.percentage ? `${shares.percentage}%` : "";

  const asOfDateText = shares?.asOfDate ? `As of ${shares.asOfDate}` : "";

  return (
    <>
      <UBOHandle isHidden type="source" position={Position.Top} />
      <S.Wrapper>
        <S.Label>{`${sharePercentageText} ${label}`}</S.Label>
        <S.SecondaryLabel>{asOfDateText}</S.SecondaryLabel>
      </S.Wrapper>
      <UBOHandle isHidden type="target" position={Position.Bottom} />
    </>
  );
};

export default EdgeLabelNode;
