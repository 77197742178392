import React, { FC, useState } from "react";

import { AnimatePresence } from "framer-motion";

import Flag from "components/atoms/Flag";
import WithInspector from "components/organisms/WithInspector";

import { type InformationSource } from "api/report/report-types";

import S from "./styles";

export enum BasicDetailsType {
  text = "text",
  country = "country",
  address = "address"
}

interface CountryItem {
  code: string;
  name: string;
  sources: InformationSource[];
}

interface AddressItem {
  values: string[];
  sources: InformationSource[];
}

interface TextItem {
  value: string;
  sources: InformationSource[];
}

interface BasicDetailsText {
  title: string;
  itemType: BasicDetailsType.text;
  items: TextItem[];
}

interface BasicDetailsCountry {
  title: string;
  itemType: BasicDetailsType.country;
  items: CountryItem[];
}

interface BasicDetailsAddress {
  title: string;
  itemType: BasicDetailsType.address;
  items: AddressItem[];
}

export type BasicDetail =
  | BasicDetailsText
  | BasicDetailsCountry
  | BasicDetailsAddress;

const DEFAULT_NUMBER_OF_ITEMS_TO_SHOW = 3;

const DetailsItem: FC<BasicDetail> = ({ items, itemType, title }) => {
  const [showAll, setShowAll] = useState(false);

  const numberOfItemsToShow =
    itemType === BasicDetailsType.text ? DEFAULT_NUMBER_OF_ITEMS_TO_SHOW : 1;

  const itemsToShow = showAll ? items : items.slice(0, numberOfItemsToShow);

  const renderItems = () => {
    if (items.length === 0) return <>-</>;

    switch (itemType) {
      case BasicDetailsType.country: {
        return (itemsToShow as CountryItem[]).map(({ code, name, sources }) => (
          <S.DetailsItemValue
            key={`DetailsItemValue-${name}`}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
          >
            <Flag code={code} showCodeString={false} />
            <WithInspector sources={sources}>{name}</WithInspector>
          </S.DetailsItemValue>
        ));
      }
      case BasicDetailsType.address: {
        return (itemsToShow as AddressItem[]).map(({ values, sources }) => (
          <S.DetailsItemLargeValue
            key={`DetailsItemValue-${items.join("")}`}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
          >
            <WithInspector sources={sources}>
              {values.map(value => (
                <div key={`DetailsItemValueAddress-${value}`}>{value}</div>
              ))}
            </WithInspector>
          </S.DetailsItemLargeValue>
        ));
      }
      default: {
        return (itemsToShow as TextItem[]).map(({ value, sources }) => (
          <S.DetailsItemValue
            key={`DetailsItemValue-${value}`}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
          >
            <WithInspector sources={sources}>{value}</WithInspector>
          </S.DetailsItemValue>
        ));
      }
    }
  };

  const onToggleItemsDisplay = () => setShowAll(prev => !prev);

  return (
    <S.DetailsItem>
      <S.DetailsItemHeading level={5}>{title}</S.DetailsItemHeading>
      <S.DetailsItemValues>
        <AnimatePresence initial={false}>{renderItems()}</AnimatePresence>
      </S.DetailsItemValues>
      {items.length > numberOfItemsToShow && !showAll && (
        <S.ToggleItemsButton onClick={onToggleItemsDisplay}>
          Show all {items.length}
        </S.ToggleItemsButton>
      )}
    </S.DetailsItem>
  );
};

interface Props {
  title: string;
  details: BasicDetail[][];
}

const BasicDetails: FC<Props> = ({ title, details }) => {
  return (
    <S.Container>
      <S.Heading level={5}>{title}</S.Heading>

      <S.DetailsContainer>
        {details.map((detailItems, index) => (
          <S.DetailsSection
            key={`DetailsSection-${detailItems.length}-${index}`}
          >
            {detailItems.map(detail => (
              <DetailsItem key={`DetailsItem-${detail.title}`} {...detail} />
            ))}
          </S.DetailsSection>
        ))}
      </S.DetailsContainer>
    </S.Container>
  );
};

export default BasicDetails;
