import React, { FC, CSSProperties } from "react";

import ReactTooltip from "components/atoms/ReactTooltip";

import S from "./styles";

const BASE_PATH = "/images/icons/flags/";

interface Props {
  code: string;
  name?: string;
  showCodeString?: boolean;
  showAsCircle?: boolean;
  className?: string;
  style?: CSSProperties;
}

const Flag: FC<Props> = ({
  code,
  name,
  showCodeString = true,
  showAsCircle = false,
  className,
  style
}) => {
  const image = (
    <img alt={name} src={`${BASE_PATH}${code?.toUpperCase()}.svg`} />
  );

  if (showAsCircle) {
    return (
      <ReactTooltip tooltip={name}>
        <S.CircleContainer>{image}</S.CircleContainer>
      </ReactTooltip>
    );
  }

  return (
    <S.Container style={style} className={className}>
      <div>
        {name ? <ReactTooltip tooltip={name}>{image}</ReactTooltip> : image}
      </div>
      {showCodeString && <S.CountryCode>{code.toUpperCase()}</S.CountryCode>}
    </S.Container>
  );
};

export default Flag;
