import { styled } from "@linaria/react";

const Wrapper = styled.div`
  padding: 8px;
  min-height: 134px;
  width: 159px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  border-radius: 20px;
`;

const S = { Wrapper };

export default S;
