import React, { FC, useState, useEffect } from "react";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import Heading from "components/atoms/Heading";
import ModalContainer, {
  Variant as ModalVariant
} from "components/molecules/ModalContainer";
import LoadingScreen from "components/organisms/UBODiagram/LoadingScreen";
import EnquiryApi from "api/enquiry/Enquiry";
import { SubjectType, OrganisationContextType } from "api/enquiry/types";
import { UboReportSource } from "api/ubo-reports";
import S from "./styles";

enum NewReportState {
  idle = "idle",
  success = "success",
  loading = "loading",
  error = "error"
}

interface Props {
  label: string;
  sources: UboReportSource[];
  isOpen: boolean;
  onClose: () => void;
}

const NewReportModal: FC<Props> = ({ label, sources, isOpen, onClose }) => {
  const [newReportEnquiryId, setNewReportEnquiryId] = useState<
    string | undefined
  >(undefined);
  const [newReportState, setNewReportState] = useState(NewReportState.idle);

  useEffect(() => {
    if (newReportState !== NewReportState.success) return;
    if (!newReportEnquiryId) return;

    window.open(`/report/${newReportEnquiryId}`, "_blank");
    setTimeout(() => {}, 3000);
  }, [newReportState, newReportEnquiryId]);

  const onNewReport = () => {
    setNewReportState(NewReportState.loading);
    const enquiryApi = new EnquiryApi(false);

    const createRequestBody = {
      subject: {
        type: SubjectType.Organisation,
        value: label
      },
      contexts: sources.map(source => ({
        type: OrganisationContextType.WebAddress,
        value: source.url
      })),
      activeFeatures: {
        OrgSocialProfileContradiction: true,
        CROrgMatchingOnlyAgainstSubject: false
      },
      projectReference: ""
    };

    enquiryApi
      .create(createRequestBody)
      .then(response => {
        if (response) {
          setNewReportState(NewReportState.success);
          setNewReportEnquiryId(response.enquiryId);
        } else {
          setNewReportState(NewReportState.error);
        }
      })
      .catch(() => {
        setNewReportState(NewReportState.error);
      });
  };

  const renderModalContent = () => {
    switch (newReportState) {
      case NewReportState.loading: {
        return <LoadingScreen />;
      }
      case NewReportState.success: {
        return (
          <>
            <Heading level={4}>
              <strong>Report successfully started</strong>
            </Heading>
            <p>
              Your report will open shortly. If it doesn’t, you can{" "}
              <a
                href={`/report/${newReportEnquiryId}`}
                target="_blank"
                rel="noreferrer"
              >
                open it manually
              </a>
              .
            </p>
          </>
        );
      }
      case NewReportState.error: {
        return (
          <>
            <Heading level={4}>
              <strong>Error running report</strong>
            </Heading>
            <p>
              There was an issue running a report on
              <br />
              <strong>{label}</strong>
            </p>
            <p>
              The report will open in a new tab and will count towards your
              report usage.
            </p>

            <S.ModalButtonsContainer>
              <ButtonNew
                type={ButtonType.OutlinedLight}
                size={ButtonSize.Medium}
                text="Cancel"
                onClick={onClose}
              />
              <ButtonNew
                type={ButtonType.FilledLight}
                size={ButtonSize.Medium}
                text="Retry"
                onClick={onNewReport}
              />
            </S.ModalButtonsContainer>
          </>
        );
      }
      default: {
        return (
          <>
            <Heading level={4}>
              <strong>New report</strong>
            </Heading>
            <p>
              Would you like to run a new report on
              <br />
              <strong>{label}</strong>
            </p>
            <p>
              The report will open in a new tab and will count towards your
              report usage.
            </p>

            <S.ModalButtonsContainer>
              <ButtonNew
                type={ButtonType.OutlinedLight}
                size={ButtonSize.Medium}
                text="Cancel"
                onClick={onClose}
              />
              <ButtonNew
                type={ButtonType.FilledLight}
                size={ButtonSize.Medium}
                text="Run report"
                onClick={onNewReport}
              />
            </S.ModalButtonsContainer>
          </>
        );
      }
    }
  };

  return (
    <ModalContainer
      width={420}
      isOpen={isOpen}
      toggleOpen={onClose}
      onExitClick={onClose}
      variant={ModalVariant.LIGHT}
    >
      <S.ModalContainer>{renderModalContent()}</S.ModalContainer>
    </ModalContainer>
  );
};

export default NewReportModal;
