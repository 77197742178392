import React from "react";

import Heading from "components/atoms/Heading";
import S from "./styles";

const LoadingScreen = () => {
  return (
    <S.LoadingContainer>
      <S.LoadingLogo>
        <S.LoadingSpinner />
        <S.Logo />
      </S.LoadingLogo>
      <Heading level={4}>Loading</Heading>
    </S.LoadingContainer>
  );
};

export default LoadingScreen;
