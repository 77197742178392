import { styled } from "@linaria/react";
import BaseHeading from "components/atoms/Heading";
import { grey, green, standardColors } from "styles/colors";
import { Info } from "react-feather";
import { hexToRgb } from "styles/hexToRgb";
import { fontSize } from "styles/fonts";

const Container = styled.div`
  background-color: ${standardColors.white};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 475px;
  padding: 96px 0;
  margin: auto;
  gap: 8px;
`;

const IconContainer = styled.div`
  background: ${() => {
    const { r, g, b } = hexToRgb(green.xapienGreen);
    return `rgba(${r}, ${g}, ${b}, 0.15)`;
  }};
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(Info)`
  width: 42px;
  height: 42px;
  color: ${green.xapienGreen};
`;

const Heading = styled(BaseHeading)`
  color: ${standardColors.black};
`;

const Paragraph = styled.div`
  text-align: center;
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  margin-bottom: 8px;
`;

const S = {
  Container,
  Content,
  IconContainer,
  Icon,
  Heading,
  Paragraph
};

export default S;
