import { FetchResult } from "api/types";
import { apm } from "@elastic/apm-rum";
import { getHeaders } from "services/http";
import config from "config";

import type { UboResponse, UboReport } from "./types";

export { UboEntityType } from "./types";

export type {
  UboReportCountry,
  UboReportSource,
  UboReportShares,
  UboReportIdentifier,
  UboReport,
  UboReportNode,
  UboReportEdge
} from "./types";

type ErrorResponseWithBody = {
  body: {
    error: string;
  };
};

const hasErrorCode = (response: unknown): response is ErrorResponseWithBody =>
  response !== null &&
  typeof response === "object" &&
  "body" in response &&
  response.body !== null &&
  typeof response.body === "object" &&
  "error" in response.body &&
  typeof response.body.error === "string";

const getHeight = (nodeType: string): number => {
  if (nodeType === "EdgeLabel") {
    return 60;
  }

  return 158;
};

const getWidth = (nodeType: string): number => {
  if (nodeType === "EdgeLabel") {
    return 89;
  }

  return 124;
};

const getEdgeLabelByNodeType = (nodeType: string): string => {
  const edgeConversionMap: Record<string, string> = {
    has_shareholder: "Shareholder",
    has_beneficial_owner: "Beneficial Owner",
    has_owner: "Owner",
    subsidiary_of: "Subsidiary",
    branch_of: "Branch",
    possibly_same_as: "Possibly Same As"
  };

  if (nodeType in edgeConversionMap) {
    return edgeConversionMap[nodeType];
  }

  return nodeType;
};

export default class UboReports {
  async getReport({
    enquiryId
  }: {
    enquiryId: string;
  }): Promise<FetchResult<UboReport>> {
    try {
      const { portalApiUrl } = config;
      const endpoint = `${portalApiUrl}/reports/${enquiryId}/ubo-data`;

      const response = await fetch(endpoint, {
        headers: await getHeaders()
      });

      const data = (await response.json()) as UboResponse;

      const nodes = data.nodes.map(
        ({
          id,
          entityType,
          name,
          addresses,
          shares,
          countries,
          identifiers,
          sources
        }) => ({
          id,
          draggable: false,
          type: entityType,
          width: getWidth(entityType),
          height: getHeight(entityType),
          position: { x: 0, y: 0 },
          data: {
            label: getEdgeLabelByNodeType(name),
            type: entityType ?? "root",
            shares,
            countries: countries ?? [],
            identifiers: identifiers ?? [],
            addresses: addresses ?? [],
            sources: sources ?? []
          }
        })
      );

      const edges = data.edges.map(({ id, fromNodeId, toNodeId }) => ({
        id,
        source: fromNodeId,
        target: toNodeId
      }));

      return { status: true, response: { nodes, edges } };
    } catch (e: unknown) {
      apm.captureError(e as Error);

      console.error(e);

      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }
      return { status: false, message: "Failed to fetch UBO data" };
    }
  }
}
