import { Amplify } from "aws-amplify";
import { amplifyConfig } from "config";
import { stage } from "services/stage";
import { CookieStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { apm } from "@elastic/apm-rum";

import {
  FetchAuthSessionOptions,
  fetchAuthSession,
  getCurrentUser,
  setUpTOTP
} from "aws-amplify/auth";

export const apiToken = async () => {
  try {
    const { tokens } = await fetchAuthSession();
    return tokens?.idToken?.toString() ?? "";
  } catch (e) {
    apm.captureError(e as Error);
    console.error(e);
    return "";
  }
};

export const getAuthenticatedUser = async (
  options?: FetchAuthSessionOptions
) => {
  const { username, signInDetails } = await getCurrentUser();
  const { tokens: session } = await fetchAuthSession(options);

  return {
    username,
    session,
    authenticationFlowType: signInDetails?.authFlowType
  };
};

export const getQrUriForMfa = async (appName: string) => {
  const { getSetupUri } = await setUpTOTP();
  let { username } = await getAuthenticatedUser();
  username = username.includes("+") ? username.split("+")[0] : username;
  const qrUriString = getSetupUri(appName, username).toString();

  return qrUriString;
};

export enum AuthenticationConfig {
  HUB = "hub",
  PORTAL = "portal"
}

export function setAuthenticationConfig(config: AuthenticationConfig) {
  Amplify.configure(amplifyConfig[config]);
  const domain = window.location.host.includes("localhost")
    ? ""
    : `.portal.${stage}.app.xapien.com`;
  cognitoUserPoolsTokenProvider.setKeyValueStorage(
    new CookieStorage({ domain })
  );
}
