import React, { FC, useState } from "react";
import { Position } from "reactflow";
import Popover from "components/atoms/Popover";
import { UBOHandle, UBONodeWrapper } from "components/atoms/UBO";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import UBOProfileCard from "components/molecules/UBOProfileCard";
import NewReportModal from "components/organisms/NewReportModal";
import type { UboReportNode } from "api/ubo-reports";
import { UboEntityType } from "api/ubo-reports";
import S from "./styles";

interface Props {
  data: UboReportNode["data"];
}

const getIconComponent = (type: UboEntityType) => {
  if (type === UboEntityType.person) {
    return <S.PersonIcon />;
  }
  return <S.OrganisationIcon />;
};

const DataNode: FC<Props> = ({ data }) => {
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const { type, label, sources } = data;
  const isBusiness = type === UboEntityType.company;

  const onCloseReportModal = () => setIsReportModalOpen(false);
  const onOpenReportModal = () => setIsReportModalOpen(true);

  return (
    <>
      <UBOHandle isHidden type="source" position={Position.Top} />
      <UBONodeWrapper>
        <Popover
          alignment="right"
          position="fixed"
          trigger="click"
          content={<UBOProfileCard data={data} isBusiness={isBusiness} />}
        >
          <S.LabelContainer>
            {getIconComponent(type)}
            <S.Label>{label}</S.Label>
          </S.LabelContainer>
        </Popover>
        <ButtonNew
          onClick={onOpenReportModal}
          type={ButtonType.OutlinedLight}
          size={ButtonSize.Medium}
          text="New report"
        />
      </UBONodeWrapper>
      <UBOHandle isHidden type="target" position={Position.Bottom} />
      <NewReportModal
        label={label}
        sources={sources}
        isOpen={isReportModalOpen}
        onClose={onCloseReportModal}
      />
    </>
  );
};

export default DataNode;
